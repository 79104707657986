import * as React from "react";
import testimonial1 from "@images/testimonials/testimonial-1.png";
import testimonial2 from "@images/testimonials/testimonial-2.png";
import testimonial3 from "@images/testimonials/testimonial-3.png";
import testimonial4 from "@images/testimonials/testimonial-4.png";
import testimonial5 from "@images/testimonials/testimonial-5.png";
import icashLogo from "@images/icash-logo-white.svg";

export const TESTIMONIALS = [
  {
    children: (
      <>
        When I need
        <br />
        help
        <br />
        it is always there for me.
        <br />
        My life saviour
      </>
    ),
    city: "Toronto. City in Ontario",
    description: "mother of two kids John and Kobo",
    name: "Tamara",
    src: testimonial1,
    logo: icashLogo
  },
  {
    children: (
      <>
       Black tank
        <br />
        Long-sleeved black T-shirt
        <br />
        Black turtleneck
        <br />
        White button-down shirt
        <br />
        Crisp white blouse...
      </>
    ),
    city: "Winnipeg. City in Manitoba",
    description: "student",
    name: "Sonia",
    src: testimonial2,
    logo: icashLogo
  },
  {
    children: (
      <>
        I finally found the peace 
        <br />
        when got my new 
        <br />
        iCash gift card
      </>
    ),
    city: "Edmonton. City in Alberta",
    description: "student",
    name: "John",
    src: testimonial3,
    logo: icashLogo
  },
  {
    children: (
      <>
        The app 
        <br />
        is my best friend
      </>
    ),
    city: "Winnipeg. City in Manitoba",
    description: "music teacher",
    name: "Rehanita",
    src: testimonial4,
    logo: icashLogo
  },
  {
    children: (
      <>
        Never was easier 
        <br />
        to get some money 
        <br />
        right now
      </>
    ),
    city: "Winnipeg. City in Manitoba",
    description: "",
    name: "Ranol and Metron",
    src: testimonial5,
    logo: icashLogo
  },
  {
    children: (
      <>
        Thank you for your help
      </>
    ),
    city: "Toronto. City in Ontario",
    description: "music student",
    name: "Nicolina",
    src: testimonial1,
    logo: icashLogo
  },
];
