import * as React from "react";

import { LayoutProps } from "../../types";

import { HorizontalScroll, Layout, LayoutDescription, LayoutHeader, LayoutTitle, TestimonialCard, TestimonialsCaption } from "@brainfinance/icash-component-library";
import { TESTIMONIALS } from "./testimonials-data";
import { generateTitle } from "@helpers/generateTitle";

export const Testimonials = (props:LayoutProps) => (
  <Layout className="sm:overflow-x-initial mb-[132px] md:mb-[88px] sm:mb-[66px]">
    <LayoutHeader>
      <LayoutTitle className="max-w-[800px] mb-3.75">
        {generateTitle({
          title: props.title,
          green: props.green,
        })}
      </LayoutTitle>
      <LayoutDescription className="max-w-[800px] mb-3.75">{props.description}</LayoutDescription>
    </LayoutHeader>
    <HorizontalScroll classBox="space-x-[0.75rem] pr-[6rem] pb-[3.25rem]">
      {TESTIMONIALS.map((testimonial, key) => (
        <TestimonialCard key={key} {...testimonial} />
      ))}
    </HorizontalScroll>
    <TestimonialsCaption>
      Since 2016 we have happily served over half of the million customers.
    </TestimonialsCaption>
  </Layout>
);

Testimonials.defaultProps = {
  title: "Find out what our customers think about our instant loans!",
  gray: "",
  green: "our customers",
  description:
    "With thousands of positive reviews, iCash has achieved an impressive 4.9 out of 5 rating for instant loans! Check out what our happy customers are saying about what makes us so great!"
};