import React from "react";

export const Disclaimer = () => (
  <div className="global--small text-interface-200 text-center w-[900px] mx-auto py-[44px] md:w-[540px] md:mb-[22px] sm:w-full sm:text-left sm:px-[22px] sm:mb-0">
    Maximum repayment period 62 days. APR is 109.50%. For a $300 loan of 14 days,
    the total payback amount is $345. Interest fees: Alberta $15 per 100, British Columbia $15 per 100,
    Manitoba $17 per 100, New Brunswick $15 per 100, Nova Scotia $19 per 100, Ontario $15 per 100,
    PEI $15 per 100. 372 Bertha Street, Hawkesbury, Ontario, K6A 2A8. iCASH only offers online services.
    Please note that we cannot accept customers or conduct any transactions from this location.
  </div>
);
