import React, { ReactNode } from "react";

import { Button, InfoCard } from "@brainfinance/icash-component-library";
import defaultImg from "@images/default.png";

export const ICashAdvantages = (props: React.PropsWithChildren<{
  title: ReactNode;
  description: string;
  fastApplicationImg: string;
  noDocumentImg: string;
  noHiddenImg: string;
  instantImg: string;
  eTransferImg: string;
  cashbackImg: string;
  fastApplicationImgSrcSet: string;
  noDocumentImgSrcSet: string;
  noHiddenImgSrcSet: string;
  instantImgSrcSet: string;
  eTransferImgSrcSet: string;
  cashbackImgSrcSet: string;
  onClick: () => void;
  buttonText: string;
}>) => (
  <div className="px-[22px] flex flex-col items-center mb-[132px] md:mb-[88px] sm:mb-[66px]">
    {props.title}
    <div className="global--paragraph text-center max-w-[660px] mx-auto mb-[51px] md:max-w-[540px] md:mb-[42px] sm:w-full sm:text-left sm:mb-[20px]">
      {props.description}
    </div>
    <div className="w-full max-w-[1437px] flex flex-wrap mx-auto justify-center mb-[8px] sm:mb-[32px]">
      <InfoCard
        className="mb-[58px] mx-[16px] sm:m-0"
        title="Fast online application"
        imgSrc={props.fastApplicationImg}
        imgSetSrc={props.fastApplicationImgSrcSet}
      >
        <p className="global--paragraph text-interface-300 mb-[16px] sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          Our online application only takes a few minutes to fill out. We make it easy to borrow money quickly and effortlessly.
        </p>
        <p className="global--paragraph text-interface-300 sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          <a href="#" className="global--link">Apply</a>&nbsp;with our mobile app or online any time.
        </p>
      </InfoCard>
      <InfoCard
        className="mb-[58px] mx-[16px] sm:m-0"
        title="No documents required"
        imgSrc={props.noDocumentImg}
        imgSetSrc={props.noDocumentImgSrcSet}
      >
        <p className="global--paragraph text-interface-300 mb-[16px] sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          Our online loan application is streamlined.
        </p>
        <p className="global--paragraph text-interface-300 mb-[16px] sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          We have all the tools to automatically evaluate your application without asking for any documents. 
        </p>
        <p className="global--paragraph text-interface-300 sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          No need to wait in line, fax long forms, or print out bank statements.
        </p>
      </InfoCard>
      <InfoCard
        className="mb-[58px] mx-[16px] sm:m-0"
        title="No hidden fees"
        imgSrc={props.noHiddenImg}
        imgSetSrc={props.noHiddenImgSrcSet}
      >
        <p className="global--paragraph text-interface-300 mb-[16px] sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          We are committed to responsible and transparent lending.
        </p>
        <p className="global--paragraph text-interface-300 sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          We make sure all the fees involved in your loan are displayed in a clear and understandable manner so you can make an informed decision.
        </p>
      </InfoCard>
      <InfoCard
        className="mb-[58px] mx-[16px] sm:m-0"
        title="Instant approval 24/7"
        imgSrc={props.instantImg}
        imgSetSrc={props.instantImgSrcSet}
      >
        <p className="global--paragraph text-interface-300 mb-[16px] sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          No more waiting around to find out if you got approved. 
        </p>
        <p className="global--paragraph text-interface-300 sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          We use AI and machine learning to automatically evaluate your application.
        </p>
      </InfoCard>
      <InfoCard
        className="mb-[58px] mx-[16px] sm:m-0"
        title="E-Transfer in minutes 24/7"
        imgSrc={props.eTransferImg}
        imgSetSrc={props.eTransferImgSrcSet}
      >
        <p className="global--paragraph text-interface-300 sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          Once you sign your loan agreement your funds will be automatically e-Transfered to you within two minutes!
        </p>
      </InfoCard>
      <InfoCard
        className="mb-[58px] mx-[16px] sm:m-0"
        title="Up to 20% cashback"
        imgSrc={props.cashbackImg}
        imgSetSrc={props.cashbackImgSrcSet}
      >
        <p className="global--paragraph text-interface-300 sm:!text-[15px] sm:!leading-[20px] sm:!tracking-[-0.25px]">
          Our customers have the opportunity to earn a percentage back on the cost of borrowing of every loan paid back. 
        </p>
      </InfoCard>
    </div>
    <Button appearance="primary" className="mx-auto sm:w-full" size="large" onClick={props.onClick}>
      {props.buttonText}
    </Button>
  </div>
);


ICashAdvantages.defaultProps = {
  title: (
    <div className="global--heading text-interface-500 mx-auto w-fit mb-[22px] md:mb-[16px] sm:mb-[19px]">
      Why Canadians choose&nbsp;<span className="text-brand-green">iCash loans</span>
    </div>
  ),
  description: 'iCash offers easy, fast, and secure online loans to Canadians, 24 hours a day and 7 days a week. Funds are sent via e-Transfer and received the same day.',
  fastApplicationImg: defaultImg,
  noDocumentImg: defaultImg,
  noHiddenImg: defaultImg,
  instantImg: defaultImg,
  eTransferImg: defaultImg,
  cashbackImg: defaultImg,
  fastApplicationImgSrcSet: '',
  noDocumentImgSrcSet: '',
  noHiddenImgSrcSet: '',
  instantImgSrcSet: '',
  eTransferImgSrcSet: '',
  cashbackImgSrcSet: '',
  onClick: () => {},
  buttonText: 'Learn more about iCash',
};