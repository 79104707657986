import React from "react";

import { Button, FlowPresentationCard } from "@brainfinance/icash-component-library";
import { navigate } from "gatsby";
import { generateTitle } from "@helpers/generateTitle";
import instantLoanImg from "@images/mobile-instant-loan.png";
import loanApprovedImg from "@images/mobile-loan-approved.png";
import moneySentImg from "@images/mobile-money-sent.png";
import './styles.css';

export const HowInstantLoansWork = (props: React.PropsWithChildren<{
  className?: string,
}>) => (
  <div className={`mt-[77px] mb-[143px] md:mt-[44px] md:mb-[88px] sm:mb-[66px]${props.className ? ` ${props.className}` : ''}`}>
    <div className="text-center mb-[22px] md:mb-[16px] sm:text-left sm:px-[22px]">
      {generateTitle({
        title: 'How instant loans work',
        green: 'instant loans',
      })}
    </div>
    <div className="text-center global--paragraph text-interface-300 mb-[66px] md:mb-[33px] md:max-w-[386px] md:mx-auto sm:w-full sm:text-left sm:px-[22px] sm:mx-0">
      Take control of your finances with financial products designed just for you.
    </div>
    <div className="flex mx-auto gap-5 justify-center md:justify-start md:mx-[63px] md:flex-wrap sm:mx-[11px]">
      <div className="md:w-[calc(50%_-_10px)] sm:w-full sm:flex sm:justify-center">
        <FlowPresentationCard step={1} title="Apply online" image={instantLoanImg} className="h-full mt-[42px] pt-[44px]">
          Getting a loan with us is fast and easy. Simply select your loan step, repayment plan and fill in the necessary
          information.
        </FlowPresentationCard>
      </div>
      <div className="md:mt-[392px] md:w-[calc(50%_-_10px)] sm:w-full sm:mt-0 sm:flex sm:justify-center">
        <FlowPresentationCard step={2} title="Instant decision" image={loanApprovedImg} className="h-full mt-[42px] pt-[44px]">
          Our fully automated online application provides you with an instant decision. Once approved, all you have to do
          is electronically sign your contract!
        </FlowPresentationCard>
      </div>
      <div className="md:w-[calc(50%_-_10px)] md:mt-[-342px] sm:w-full sm:mt-0 sm:flex sm:justify-center">
        <FlowPresentationCard step={3} title="Get funds" image={moneySentImg} className="h-full mt-[42px] pt-[44px]">
          Once your contract is signed, your funds will be sent by e-Transfer in the next 5 minutes! Fast, convenient and
          hassle-free. Funds are sent 24/7.
        </FlowPresentationCard>
      </div>
    </div>
    <div className="text-center mt-[66px] sm:mt-[44px] sm:mx-[22px]">
      <Button appearance="primary" className="sm:w-full" size="large" onClick={() => navigate("/dashboard/instant-loan")}>
        Get an instant loan
      </Button>
    </div>
  </div>
);
