import * as React from "react";
import { navigate } from "gatsby";
import { Box } from "@components/box";

export const TrustPilotWidget = () => {
  const ref = React.useRef(null);

  React.useEffect(() => {
    // @ts-ignore
    if (window.Trustpilot) {
      // @ts-ignore
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
    <Box
      display="flex"
      className="justify-center bg-[#F4FCFA] py-[88px] px-[22px] flex-col items-center mb-[132px] md:mb-[79px] md:py-[44px] sm:mb-[65px]"
    >
      <h1 className="global--page-heading mb-[64px] text-center md:mb-[44px] md:max-w-[470px] sm:max-w-full sm:text-left sm:text-[30px] sm:leading-[31px]">
        Behind each review is&nbsp;
        <span className="text-brand-green">experience that matters</span>
      </h1>
      <div
        ref={ref}
        className="trustpilot-widget w-[100%]"
        data-locale="en-US"
        data-template-id="54ad5defc6454f065c28af8b"
        data-businessunit-id="5a54edd59046f00001ad04bf"
        data-style-height="180px"
        data-style-width="100%"
        data-theme="light"
        data-stars="4,5"
        data-review-languages="en"
        data-font-family="Poppins"
      >
        <a href="https://www.trustpilot.com/review/icash.ca" target="_blank" rel="noopener">
          Trustpilot
        </a>
      </div>
      <div className="global--caption text-center mt-[72px] md:mt-[60px] sm:mt-[34px]">
        Since 2016 we have happily served over half of the million customers. Read more&nbsp;
        <a className="global--link" onClick={() => navigate("/")}>
          reviews
        </a>
        .
      </div>
    </Box>
  );
};
